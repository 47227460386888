import axios from 'axios';

const tokenKey = 'tvs.token';

const AppAPIInstance = axios.create({
  baseURL: process.env.REACT_APP_ECE_URL
    ? process.env.REACT_APP_ECE_URL
    : 'https://u9pbcagvw5.execute-api.ap-southeast-1.amazonaws.com/dev/api',
});

AppAPIInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
AppAPIInstance.defaults.headers.common['Content-Type'] = 'application/json';

const token = localStorage.getItem(tokenKey);

AppAPIInstance.interceptors.request.use(
  async (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      Authorization: `Bearer ${JSON.parse(token || '')}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

AppAPIInstance.interceptors.response.use(
  // eslint-disable-next-line consistent-return
  (response) => {
    if (response && response.data) {
      return response.data || null;
    }
  },
  (error) => {
    return Promise.reject(error?.response?.data || error);
  },
);

export default AppAPIInstance;
