import axios from 'axios';

export const requestUploadFile = async (id, fileExt, uploadFile) => {
  const tokenKey = 'tvs.token';
  const token = localStorage.getItem(tokenKey);
  const timestamp = Date.now();

  {/* eslint-disable  */ }
  const resp: any = await axios.get(
    `${process.env.REACT_APP_API_URL + '/v1/upload-url?type=dc&id={/id}&suffix={/timestamp}&ext={/fileExt}'.replace('{/id}', id)
      .replace('{/timestamp}', timestamp.toString())
      .replace('{/fileExt}', fileExt)}`,
    {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    },
  );

  const { filename, uploadUrl } = resp.data;
  await axios.put(uploadUrl, uploadFile);
  return { filename, uploadUrl };
};
